'use client';
import React, { useEffect, useState, useContext,  Suspense } from 'react';
import { Box, Center, Spinner, Text,  } from '@chakra-ui/react';
import Carrousel from '@/components/Carrousel';
import { usePathname, useRouter } from 'next/navigation';
import ContactUs from './contactUs';
import IwinData from './iwinData';
//import ToInvest from './toInvest';
//import Footer from '@/components/Footer';
import NavBarIwin from '@/components/NavBarIwin';
//import ChatBot from '@/components/ChatBot';
//import SelectFilters from './selectFilters';
import apiHelpers from '@/utils/apiHelpers';
import MostChosen from './mostChosen';

import OportunietiesCarousel from './OportunietiesCarousel';
import DevelopmentCarousel from './DevelopmentCarousel';



const Footer = React.lazy(() => import('@/components/Footer'));
const ChatBot = React.lazy(() => import('@/components/ChatBot'));
const ToInvest = React.lazy(() => import('./toInvest'));
const SelectFilters = React.lazy(() => import('./selectFilters'));

const Landing = props => {
 const [loading, setLoading] = useState(true);

  const [isOpen,setIsOpen]=useState(false)
  const [deviceType, setDeviceType] = useState('desktop'); 
  const [data, setData]=useState({
    devOportunities:props.devOportunities,
    oportunities:props.oportunities,
    propertiesCount:props.propertiesCount,
    avilableLocalsCount:props.avilableLocalsCount,
    developmentsCount:props.developmentsCount,
   isCharged:props.isCharged,
  })
  const [files, setFiles]=useState([])




  useEffect(() => {
    console.log('hola')
    const updateDeviceType = () => {
      const width = window.innerWidth;
      if (width < 650) {
        setDeviceType('mobile');
        } else if (width < 1025) {
          setDeviceType('tablet');
          } else {
            setDeviceType('desktop');
            }
            };
            
            updateDeviceType(); 
            window.addEventListener('resize', updateDeviceType); 
            
            return () => {
              window.removeEventListener('resize', updateDeviceType); 
              };
              }, []);
            



              useEffect(() => {
           
                const fetchData = async () => {
    

                  try {
                    if (data.isCharged) {
                      const imagesResponse = await apiHelpers.getFiles({
                        where: {
                          section: ['carrousel', 'palermo', 'belgrano', 'nuñez', 'recoleta', 'las cañitas'],
                        },
                      });
              
                      const filesWithUrls = imagesResponse?.data.map(file => ({
                        ...file,
                        url: apiHelpers.getFileURL(file.id),
                      }));
              
                      setFiles(filesWithUrls);
                    }
                  } catch (error) {
                    console.error('Error fetching images:', error);
                  }
                };
              
                fetchData();
              }, []);


  const breakpoints = {
    base: '20em',
    sm: '25em',
    md: '45em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  };



  const toInvest = [
    { name: 'Participá', subTitle: 'de un proyecto', image: 'participar.webp', goTo: '/inversiones' },
    { name: 'En Pozo', image: 'pozo.webp', goTo: 'En Pozo', type: 'Emprendimiento' },
    { name: 'En Obra', image: 'obra.webp', goTo: 'En Obra', type: 'Emprendimiento' },
    { name: 'Terminados', image: 'terminado.webp', goTo: 'Terminado', type: 'Emprendimiento' },
    { name: 'Retasados', type: 'Propiedad', goTo: 'oportunidades', image: 'renta.webp' },
  ];

const mostChosenDefaultImg = [
  { id:1, url:'/img/nunez.webp',section:'nuñez' },
  { id:2, url:'/img/belgrano.webp',section:'belgrano' },
  { id:3, url:'/img/recoleta.webp',section:'recoleta' },
  { id:4, url:'/img/canitas.webp',section:'las cañitas' },
  { id:5, url:'/img/palermo.webp',section:'palermo' },
];
  
  const openMobileFilters=(isOpen)=>{
    setIsOpen(isOpen)
  }


  return (

    <Box>


      <NavBarIwin
        pos={'fixed'}

      />
      <Carrousel
        defaultImg={'/img/slider1.webp'}
        images={files}
        autoPlay={true}
        showButtons={false}
        breakpoints={breakpoints}></Carrousel>

      <Center w={'95%'} zIndex={3} display={'block'} m={'0 auto'}>
        <Box
          position={'relative'}
          w={'100%'}
          display={'flex'}
          justifyContent={'center'}>
             <Suspense fallback={<Spinner />}>
   
          <SelectFilters
            loader={loading}

            isOpen={openMobileFilters}
            
          />
          </Suspense>
          {/* <PropertiesFilters
            count={count}
            uploadFilters={searchFilters}
            loadData={handleChangeFilters}
  goToProps={goToProperties}></PropertiesFilters>*/}
        </Box>
      </Center>
      <Box
        mt={{
          base: isOpen ? '670px' : '90px',
          sm: '430px',
          md: '350px',
          lg: '220px',
          xl: '100px',
        }}>
        <Box
          m="0px auto"
          width={'95%'}
          display={'flex'}
          flexDirection={'column'}>
          <Text
            fontSize={{ base: '20px', sm: '25px', md: '25px', lg: '35px' }}
            m={{ base: '10px', sm: '10px', md: '40px', lg: '55px', xl: '50px' }}
            color="gray.700"
            fontWeight="400"
            fontFamily="Heebo, sans-serif"
            textAlign="center"
            lineHeight="1"
            letterSpacing="2px">
            Emprendimientos Destacados
          </Text>

          {!data?.devOportunities?.length && loading ? (
            <Box
              mb={5}
              minH={'100px'}
              p={2}
              w={'100%'}
              mt={0}
              borderRadius="lg">
              <Center>
                <Spinner mt={3} size="xl" color='orange' />
              </Center>
            </Box>
          ) : (
                  <DevelopmentCarousel
            deviceType={deviceType}
              id="carrouseldev"
              oportunities={data.devOportunities}
              loading={loading}
            />
          )}
        </Box>
      </Box>
      <Box
        mt={{
          base: '50px',
          sm: '60px',
          md: '70px',
          lg: '90px',
          xl: '90px',
        }}>
        <Box
          m="0px auto"
          width={'95%'}
          display={'flex'}
          flexDirection={'column'}>
          <Text
            fontSize={{ base: '20px', sm: '25px', md: '25px', lg: '35px' }}
            m={{ base: '10px', sm: '10px', md: '40px', lg: '55px', xl: '50px' }}
            color="gray.700"
            fontWeight="400"
            fontFamily="Heebo, sans-serif"
            textAlign="center"
            lineHeight="1"
            letterSpacing="2px">
            Propiedades Destacadas
          </Text>

          {!data?.oportunities?.length && loading ? (
            <Box
              mb={5}
              minH={'100px'}
              p={2}
              w={'100%'}
              mt={0}
              borderRadius="lg">
              <Center>
                <Spinner mt={3} size="xl" color='orange' />
              </Center>
            </Box>
          ) : (
            <OportunietiesCarousel
            deviceType={deviceType}
              id="carrousel"
              oportunities={data.oportunities}
              loading={loading}
            />
          )}
        </Box>
      </Box>
      <Box
        mt={'80px'}
        width={'90%'}
        margin={'0 auto'}
        //bgImage={'/img/bgpropsOP.jpg'}
        //width={'100%'}
        //height={'440px'}
        //bgSize={500}
        //zIndex={1}
        //flexDirection={'column'}
        //justifyContent={'center'}
        //objectFit={'auto'}
      >
        <MostChosen images={files.length>0 ?files : mostChosenDefaultImg }   />
      </Box>

      <Box
        mt={{ base: '30px', sm: '30px', md: '40px', lg: '55px', xl: '50px' }}
        mb={{ base: '20px', sm: '20px', md: '40px', lg: '55px', xl: '50px' }}>
        <ContactUs textAlign={'center'} />
      </Box>

      <Box
        marginTop={{ base: '80px' }}
        bg={'#999999'}
        height={{ base: '560px', sm: '260px' }}
        display={'flex'}
        alignItems={'center'}>
        <IwinData    porpertiesOnsale={data?.propertiesCount}  avilableLocalsCount={data?.avilableLocalsCount}  developmentsAvilable={data?.developmentsCount}/>
      </Box>
      <Box mt={20} width={'95%'} margin={'0 auto'}>
      <Suspense fallback={<Spinner />}>
        <ToInvest
          toInvest={toInvest} />
          </Suspense>
      </Box>
      <Suspense fallback={<Spinner />}>
<ChatBot /> 
</Suspense>
<Suspense fallback={<Spinner />}>
        <Footer justifyContent={'space-around'} />
      </Suspense>
    


    </Box>
 

  );
};
export default Landing; //withAuth(Properties);
